<template>
  <div class="bulk-order">
    <div class="main">
      <!-- 进度条 -->
      <div class="order-steps">
        <!-- 第一步 -->
        <div class="order-steps-item">
          <span class="steps-number" :class="{bg: step >= 1}">01</span>       
          <div class="text-div">
            <span class="title-text">选择文件</span>
            <span class="explain-text">提交下单文件</span>
          </div>
          <div class="line-div">
            <div class="circle" :class="{bc: step >= 2}"></div>
            <div class="line" :class="{bc: step >= 2}"></div>
            <div class="circle" :class="{bc: step >= 2}"></div>
          </div>
        </div>
        <!-- 第二步 -->
        <div class="order-steps-item">
          <span class="steps-number" :class="{bg: step >= 2}">02</span>
          <div class="text-div">
            <span class="title-text">确认数据</span>
            <span class="explain-text">查看订单数据</span>
          </div>
          <div class="line-div">
            <div class="circle" :class="{bc: step >= 3}"></div>
            <div class="line" :class="{bc: step >= 3}"></div>
            <div class="circle" :class="{bc: step >= 3}"></div>
          </div>
        </div>
        <!-- 第三步 -->
        <div class="order-steps-item">
          <span class="steps-number" :class="{bg: step >= 3}">03</span>
          <div class="text-div">
            <span class="title-text">导入订单</span>
            <span class="explain-text">生成订单数据</span>
          </div>
        </div>
      </div>
      <!-- 显示内容 -->
      <div class="order-content">
        <!-- 步骤一 -->
        <BulkStep1 v-if="step === 1" @nextStep="nextStep"></BulkStep1>
        <!-- 步骤二 -->
        <BulkStep2 v-if="step === 2" @nextStep="nextStep" @goBack="goBack" :data="tableData"></BulkStep2>
        <!-- 步骤二 -->
        <BulkStep3 v-if="step === 3" ></BulkStep3>
      </div>
    </div>
  </div>
</template>
<script>
import BulkStep1 from "./BulkStep1.vue"
import BulkStep2 from "./BulkStep2.vue"
import BulkStep3 from "./BulkStep3.vue"
export default {
  name: "BulkOrder",
  components:{BulkStep1,BulkStep2,BulkStep3},
  data(){
    return {
      // step:1,
      tableData:[],
    }
  },
  computed:{
    step(){
      return this.$store.state.bulkStep
    }
  },
  methods:{
    // 下一步
    nextStep(obj){
      // this.step++;
      console.log(obj, 'step')
      this.$store.state.bulkStep = obj.step
      sessionStorage.setItem('bulkData', JSON.stringify(obj.tableData))
      // 返回的成功数组
      this.tableData = obj.tableData
      console.log(obj)
    },
    // 上一步
    goBack(){
      if(this.step > 1){
        this.$store.state.bulkStep = 1
      }
    }
  },
}
</script>
<style lang="less" scoped>
.bulk-order{
  min-height: calc(100vh - 101px);
  background-color:#f4f4f4;
  .main{
    width: 1000px;
    margin: 0 auto;
    // 步骤条
    .order-steps{
      display:flex;
      height: 199px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .order-steps-item{
        display:flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .steps-number{
          width:50px;
          display:inline-block;
          height:50px;
          line-height:50px;
          text-align: center;
          border-radius:50%;
          background-color: #D2D5DC;
          color: #fff;
          margin-right: 20px;
          font-size: 24px;
          font-weight: bold;
        }
        .text-div{
          display: flex;
          flex-direction: column;
          .title-text{
            height: 33px;
            font-size: 24px;
            font-weight: bold;
            color: #1E2B4F;
            line-height: 33px;
          }
          .explain-text{
            margin-top: 6px;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #636D88;
            line-height: 20px;
          }
        }
        .line-div{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin: 22px 15px;
          .circle{
            width:7px;
            height:7px;
            border-radius:50%;
            border: 1px solid #D2D5DC;
          }
          .line{
            width: 119px;
            border-bottom: 1px dotted #D2D5DC;
          }
          .bc{
            border-color: #6D5EFE;
          }
        }
        .bg{
          background-color: #6D5EFE;
        }
      }
    }
  }
}
</style>