<template>
  <div class="bulk-step-three">
    <div class="main">
      <div class="result-div">
        <img width="77" height="70" src="../../assets/img/icon_shopping_car.png" />
        <div class="tip-title">很棒，订单全部导入成功</div>
        <div class="shop-car-btn posi" @click="shopCarBtnClicked">去购物车看看</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BulkStep3",
  data(){
    return{

    }
  },
  methods:{
    // 去购物车看看按钮被点击
    shopCarBtnClicked(){
      console.log("去购物车看看按钮被点击");
      this.$router.push({
        path: `/ShoppingCart`,
      })
    }
  },
}
</script>

<style lang="less" scoped>
.bulk-step-three{
  width: 100%;
  .main{
    .result-div{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 344px;
      background: #FFFFFF;
      border-radius: 8px;
      .tip-title{
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #636D88;
        line-height: 22px;
        margin: 20px 0px;
      }
      .shop-car-btn{
        width: 130px;
        height: 36px;
        background: #FFFFFF;
        border-radius: 22px;
        border: 1px solid #6D5EFE;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6D5EFE;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}
</style>