<template>
  <div class="bulk-step-one">
    <div class="main">
      <!-- 提示 -->
      <div v-if="tipShow" class="top-tip">
        <div class="top-tip-title">批量下单系统使用说明：</div>
        <div class="top-tip-content">
          <span>1/ 简化下单时间及繁琐的打印过程，可以利用此系统进行批量下单节省时间。</span>
          <span>2/ 请下载批量下单 EXCEL 模板，根据表格填写后在本页面上传，一次下单。 </span>
          <span>3/ 请严格按照邮寄商品编码库如实申报包裹内件，否则将会影响清关。<span style="color:#6D5EFE;cursor:pointer;" @click="openSku">查看SKU</span></span>
        </div>
        <i class="el-icon-close posi top-tip-close" @click="tipShow = false"></i>
      </div>
      <el-alert
        title="错误提示的文案"
        style="margin-bottom:20px;"
        v-if="fail.length > 0"
        type="error"
        show-icon>
        <div slot="title">
          <div v-for="(item,index) in fail" :key="index">{{item}}</div>
        </div>
      </el-alert>
      <!-- 上传 -->
      <div class="content">
        <!-- 上传选择 -->
        <div class="upload-div" v-if="!uploadData.show">
          <el-upload
            ref="uploadExcel"
            action="#"
            :show-file-list="false"
            accept = ".xls, .xlsx"
            :auto-upload="true"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadHttpRequest">
             <div class="upload-btn">上传Excel文件</div>
          </el-upload>
          <div class="template-select">
            <el-select v-model="templateType" placeholder="下载最新的Excel模版" popper-class="my-select" @change="templateSelected(this)" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 上传内容显示 -->
        <div class="upload-show-div" v-else>
          <img width="66" src="../../assets/img/BulkOrder/step1_excel.png" />
          <div class="upload-name-text">{{uploadData.fileName}}</div>
          <div class="upload-state-div">
            <div class="loading" v-loading="true"></div>
            <div class="upload-text">正在上传</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {importOrderExcel,downloadOrderPackageExcelTemplate,getOrderPackageExcelTemplate} from '@/api/bulkOrder/bulkOrder'
export default {
  name: "BulkStep1",
  data(){
    return{
      // 是否显示提示
      tipShow: true,
      // 下拉框选项
      options: [
        // {
        //   value: 1,
        //   label: '奶粉包税'
        // }, 
        // {
        //   value: 2,
        //   label: '英国皇家邮政'
        // }, 
        // {
        //   value: 3,
        //   label: '法国邮政'
        // }, 
        // {
        //   value: 4,
        //   label: '其他服务'
        // }
      ],
      // 选择的下拉框选项
      templateType: '',
      // 上传的信息
      uploadData: {
        show: false,
        fileName: "",
      },
      // 上传错误数组
      fail:[],
    }
  },
  created() {
    this.getOrderPackageExcelTemplate();
  },
  methods:{
    getOrderPackageExcelTemplate() {
      getOrderPackageExcelTemplate().then(res => {
        if (res.data.code != 0)  {
          return this.$message.error('模板失效');
        }

        let opt = [];
        for (let i in res.data.data) {
          opt.push({
            value: i,
            label: res.data.data[i] 
          })
        }
        this.options = opt;
        console.log("opt", opt);
      }).catch(err => {
        console.log(err);
        this.$message.error("模板错误");
      });
    },
    // 跳转SKU页面
    openSku(){
      this.$router.push({
        path:'/SKU'
      })
    },
    // 模版下载选择框被选择
    templateSelected(e){
      
      console.log("templateType", this.templateType, e);
      // 奶粉报税
      this.downloadOrderPackageExcelTemplate(this.templateType);
      //下载模版
    },
    downloadOrderPackageExcelTemplate(id){
      let _that = this;
      let xhr = new XMLHttpRequest();
      let url = downloadOrderPackageExcelTemplate(id);
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      let token = localStorage.getItem("token");
      xhr.setRequestHeader('verification', token);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.withCredentials = true;
      xhr.onload = function (){
         if (xhr.status === 200) {
            // 返回200
           let blob = this.response;
            let reader = new FileReader();
            reader.readAsDataURL(blob);  // 转换为base64，可以直接放入a表情href
            // 文件流转url
            reader.onload = function(e){
            let base64 = e.target.result;
            _that.download(base64, _that.options[id].label + `_批量下单.xlsx`)
            
          }
         }else {
           _that.$message.error('下载订单详情失败')
         }
         
      }
      xhr.send();
    },
    //下载方法
    download(href, title) {
      const a = document.createElement('a');
      a.setAttribute('href', href);
      a.setAttribute('download', title);
      a.click();
    },
    // 上传前格式校验
    beforeAvatarUpload (file) {
      console.log("file" ,file)
      return new Promise((resolve, reject) => {
        let testMsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const isXlsx = testMsg === 'xlsx'
        const isXls = testMsg === 'xls'
        console.log(testMsg, 'testMsg')
        if (!isXlsx && !isXls) {
          this.$message.error('请上传正确的Excel');
          return reject(false);
        }else{
          return resolve(true);
        }
      })
    },
    // 上传方法
    uploadHttpRequest(param){
      console.log("进行上传",param);
      this.uploadData.fileName = param.file.name;
      this.uploadData.show = true;
      //上传excel
      let _this = this;
      let update = new FormData();
      update.append('file', param.file)
      importOrderExcel(update).then(res => {
        if(res.data.code === 0){
          let data = res.data.data
          // 判断数据错误数组中是否存在值
          if(data.fail.length > 0){
            this.fail = data.fail
          }else{
            this.nextStep(data.success)
          }
        }else{
          this.$message.error(res.data.message)
        }
         _this.uploadData.show = false;
      }).catch(() => {
         _this.uploadData.show = false;
      })
    },
    // 下一步的方法
    nextStep(data){
      let obj = {
        step:2,
        tableData: data
      }
      this.$emit('nextStep', obj)
    },
  },
}
</script>

<style lang="less" scoped>
.bulk-step-one{
  width: 100%;
  .main{
    padding-bottom: 20px;
    // 顶部提示
    .top-tip{
      height: 180px;
      background: #FFFBE6;
      border-radius: 8px;
      border: 1px solid #FFC17C;
      position: relative;
      margin-bottom: 20px;
      .top-tip-title{
        height: 30px;
        font-size: 24px;
        font-weight: bold;
        color: #5C2F00;
        line-height: 30px;
        margin-left: 30px;
        margin-top: 24px;
      }
      .top-tip-content{
        height: 90px;
        font-size: 14px;
        font-weight: 400;
        color: #5C2F00;
        line-height: 30px;
        margin-left: 30px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
      }
      .top-tip-close{
        font-size: 20px;
        color: #5C2F00;
        line-height: 20px;
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
    // 内容
    .content{
      height: 344px;
      background: #FFFFFF;
      border-radius: 8px;
      // 上传内容
      .upload-div{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .upload-btn{
          width: 145px;
          height: 44px;
          background: #6D5EFE;
          border-radius: 22px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          text-align: center;
          line-height: 44px;
        }
        .template-select{
          margin-top: 30px;
          ::v-deep .el-input .el-input__inner{
            width: 214px;
            height: 44px;
          }
          ::v-deep .el-select .el-input.is-focus .el-input__inner{
            border-color: #6D5EFE;
          }
          ::v-deep  .el-select .el-input__inner:focus{
            border-color: #6D5EFE;
          }
        }
      }
      // 上传内容显示
      .upload-show-div{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .upload-name-text{
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #636D88;
          line-height: 22px;
          margin: 20px 0px;
        }
        .upload-state-div{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .loading{
            width: 20px;
            height: 20px;
            margin-right: 7px;
            ::v-deep .el-loading-spinner{
              margin-top: -10px;
              .circular{
                height: 20px;
                width: 20px;
              }
              .path{
                stroke-width: 3;
                stroke:#3AB764;
              }
            }
          }
          .upload-text{
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            color: #636D88;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>