<template>
  <div class="bulk-step-two">
    <div class="main">
      <!-- 顶部提示 -->
      <div class="top-tip" v-if="topShow">
        <div class="left">
          <img width="16" src="../../assets/img/BulkOrder/step2_ok.png" />
          <div class="top-tip-text">
            上传成功，请检查数据！
          </div>
        </div>
        <img class="posi" width="14" @click="topShow = false" src="../../assets/img/BulkOrder/step2_close.png" />
      </div>
      <!-- 寄件人信息 -->
      <div class="sender-div">
        <Sender :sender="sender" :show="false" ref="bulkSender" @updateSender="updateSender"></Sender>
      </div>
      <!-- 包裹信息 -->
      <div class="package-div">
        <div class="table-div package-table">
          <el-table :data="packageData" style="width: 100%" border empty-text="暂无数据">
            <el-table-column
              fixed
              prop="index"
              label="编号"
              width="80"
              :resizable="false">
              <template slot-scope="scope">
                {{scope.$index + 1}}
              </template>
            </el-table-column>
            <el-table-column
              fixed
              prop="service"
              label="服务名称"
              width="114"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop='cosigneeName'
              label="姓名"
              width="100"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop="cosigneeIdentityCard"
              label="身份证号码"
              width="166"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop="cosigneePhone"
              label="电话"
              width="120"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop="cosigneeProvince"
              label="省"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop="cosigneeCity"
              label="市"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop="cosigneeCountry"
              label="区域"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop="cosigneeAdress"
              label="地址"
              width="120"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop="note"
              label="备注"
              :resizable="false">
            </el-table-column>
            <el-table-column
              prop="weight"
              label="重量"
              :resizable="false">
            </el-table-column>
            <template v-for="i in skuArr">
              <el-table-column
                v-bind:key="i"
                :prop="'skuList[' + i + '].name'"
                :label="'物品' + (i + 1) + '名称'"
                :resizable="false">
              </el-table-column>
              <el-table-column
                v-bind:key="i + 'a'"
                :prop="'skuList[' + i + '].number'"
                :label="'物品' + (i + 1) + '数量'"
                :resizable="false">
              </el-table-column>
            </template>
            
            <el-table-column
              fixed = "right"
              label="Action"
              width="75"
              :resizable="false">
              <template slot-scope="scope">
                <div class="edit-div posi" @click="delectedClicked(scope.$index, scope.row)">
                  删除
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="bottom-div">
      <div class="btn-div">
        <div class="clear-btn posi" @click="clearBtnClicked">
          清空数据
        </div>
        <el-button class="el-button sure-btn posi" @click="sureBtnClicked" :loading="loading">
          确认无误，批量导入订单
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Sender from "../../components/order/sender.vue"
import {defaultSenderAddress} from '../../api/address/sender/sender'
import {batchImportOrderPackage} from '@/api/bulkOrder/bulkOrder'
export default {
  name: "BulkStep2",
  components:{
    Sender
  },
  props:{
    data:{
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      // 顶部提示
      topShow: true,
      // 寄件人数据
      sender:{
        name:"",
        phone:"",
        zipCode:"",
        addressOne:"",
        addressTwo:"",
        addressThree:"",
        addressFour:"",
        defaultFlag:true
      },
      // 包裹信息
      packageData:this.data,
      loading: false,
      tableData: [],
      skuArr: [0, 1, 2, 3, 4]
    }
  },
  mounted(){
    //设置默认寄件人
    this.getDefaultSender().then(senderDefault=>{
      if(senderDefault){
        this.sender = {...senderDefault};
      }
    })

    // 刷新页面保存数据
    let bulkData =  sessionStorage.getItem('bulkData')
    if(bulkData != undefined && this.packageData.length == 0){
      console.log("bulkData", bulkData);
      this.packageData = JSON.parse(bulkData)
    }
  },
  methods:{
    //获取默认寄件人
    async getDefaultSender(){
      let data;
      await defaultSenderAddress().then(res=>{
        if(res.data && res.data.data && res.data.data.default){
          data = res.data.data.default;
        }
      })
      return data;
    },
    // 下一步的方法
    nextStep(){
      this.$emit('nextStep', 2)
    },
    // 删除按钮被点击
    delectedClicked(arg1,arg2){
      console.log("删除按钮被点击",arg1,arg2);
      this.packageData.splice(arg1,1);
    },
    //清空数据按钮被点击
    clearBtnClicked(){
      console.log("清空数据按钮被点击");
      this.packageData = [];
      //返回上一步
      this.$emit('goBack')
    },
    //确认按钮被点击
    sureBtnClicked(){
      console.log("确认按钮被点击");
      this.loading = true;
      // 寄件人验证输入
      this.$refs.bulkSender.$refs.senderForm.validate((valid) => {
        if (valid) {
          this.batchImportOrderPackage();
          this.$refs.bulkSender.setAddress();
        } else {
          this.loading = true;
          return false;
        }
      });
    },
    // 批量保存接口
    batchImportOrderPackage(){
      if(this.packageData.length === 0){
        this.$message.error('请添加有效订单')
        return
      }
      let upData = {
        orderPackageExcelIpoList:this.packageData,
        sender:this.sender
      }
      batchImportOrderPackage(upData).then(res => {
        if(res.data && res.data.code == 0){
          this.$message.success(res.data.message)
          let obj = {
            step:3,
          }
          this.$emit('nextStep', obj)
        }else{
          this.$message.error(res.data.message)
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    // 选择了地址簿中的寄件人信息使用
    updateSender(arg){
      this.sender.name = arg.name
      this.sender.phone = arg.phone
      this.sender.zipCode = arg.zipCode
      this.sender.addressOne = arg.addressOne
      this.sender.addressTwo = arg.addressTwo
      this.sender.addressThree = arg.addressThree
      this.sender.addressFour = arg.addressFour
    },
    //构造表格数据
    renderTable(packageData) {
      let data = JSON.parse(JSON.stringify(packageData));
      //获取最大sku数量
      let maxSkuNum = 0;
      data.forEach(item => {
        maxSkuNum = item.skuList.length > maxSkuNum ? item.skuList.length : maxSkuNum;
      });
    
      let skuTemp = {
        id: "",
        name: "",
        number: "",
        sku: "",
        price: ""
      };
      let tempdata = [];
      //构造表格(sku数量增加)
      data.forEach(item => {
        let tempSkuList = [];
        item.skuList.forEach(sku => {
          tempSkuList.push(sku);
        });
        let diff = maxSkuNum - tempSkuList.length;
        if (diff > 0) {
          for (let i = 0; i < diff; i++) {
            tempSkuList.push(skuTemp);
          }
        }
        item.skuList = tempSkuList;
        tempdata.push(item);
      });
      this.renderTable = tempdata;
      console.log("renderTable", this.renderTable)
    }
  },
}
</script>

<style>
/* 修改table样式 */
.bulk-step-two .package-table .el-table th.el-table__cell:nth-child(1),
.bulk-step-two .package-table .el-table th.el-table__cell:nth-child(2),
.bulk-step-two .package-table .el-table th.el-table__cell:last-child{
  background: #E8EBF2;
}
/* .bulk-step-two .table-div .el-table__body tr.hover-row>td.el-table__cell{
  background-color: #F3F4FD;
} */
/* .bulk-step-two .table-div ::-webkit-scrollbar {
  height: 5px;
  background-color: #fff;
}
.bulk-step-two .table-div ::-webkit-scrollbar-thumb {
  background: rgba(30, 43, 79, 0.5);
  border-radius: 4px;
} */
</style>

<style lang="less" scoped>
.bulk-step-two{
  width: 100%;
  .main{
    padding-bottom: 120px;
    .top-tip{
      height: 62px;
      background: #F6FFED;
      border-radius: 8px;
      border: 1px solid #B7EB8F;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      margin-bottom: 20px;
      .left{
        display: flex;
        flex-direction: row;
        align-items: center;
        .top-tip-text{
          height: 30px;
          font-size: 14px;
          font-weight: 400;
          color: #295308;
          line-height: 30px;
          margin-left: 8px;
        }
      }
    }
    .package-div{
      margin-top: 20px;
      .package-table{
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;
        .edit-div{
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #6D5EFE;
          line-height: 20px;
        }
      }
    }
  }
  .bottom-div{
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    height: 100px;
    min-width: 1400px;
    background: #FFFFFF;
    .btn-div{
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .clear-btn{
        width: 100px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 22px;
        border: 1px solid #D2D5DC;
        font-size: 16px;
        font-weight: 400;
        color: #636D88;
        line-height: 44px;
        text-align: center;
      }
      .sure-btn{
        // width: 220px;
        height: 44px;
        background: #6D5EFE;
        border-radius: 22px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        // line-height: 44px;
        text-align: center;
        margin-left: 20px;
      }
    }
  }
}
</style>