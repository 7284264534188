import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
// const resquest = `http://192.168.1.100:7000/express`

// 上传Excel
export function importOrderExcel(data) {
  return http.post(`${resquest}/frontend/orderPackage/importOrderExcel`, data);
}

// 保存批量订单
export function batchImportOrderPackage(data) {
  return http.post(`${resquest}/frontend/orderPackage/batchImportOrderPackage`, data);
}

// 下载批量上传模板  奶粉包税
export function downloadOrderPackageExcelTemplate(id) {
  return `${resquest}/frontend/orderPackage/downloadOrderPackageExcelTemplate?id=` + id;
}

// 获取Excel模板列表
export function getOrderPackageExcelTemplate() {
  return http.get(`${resquest}/frontend/orderPackage/getOrderPackageExcelTemplate`);
}